import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';

import {
  IGetResponseTask,
  IGetTaskTemplates,
  ISaveResponseTask,
  TaskByClient,
} from '../interfaces';
import { auth } from 'src/environments/environment';
import { CreatedTasksDTO, TaskResponseDTO, TaskTemplatesDTO } from '../dtos';
import { ETaskStatus, ITaskCompleted } from '../entities/task.entity';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  private API_URL: string = auth.urlConfig;
  public user: any;

  public createdTask: TaskByClient[];
  public assignedTasks: TaskByClient[];
  public assignedPendingCount: number = 0;
  public assignedCompletedCount: number = 0;
  public createdPendingCount: number = 0;
  public createdCompletedCount: number = 0;

  private isTabIndex_0: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );
  public $isTabIndex_0: Observable<number> = this.isTabIndex_0.asObservable();

  constructor(private readonly httpClient: HttpClient) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
  }

  getTaskTemplates(data: IGetTaskTemplates): Observable<TaskTemplatesDTO> {
    const params = { ...data };

    const url: string = `${this.API_URL}/tasks/by-filters`;

    return this.httpClient
      .get<TaskTemplatesDTO>(url, { params })
      .pipe(catchError(this.handleError));
  }

  getResponse(data: IGetResponseTask): Observable<TaskResponseDTO> {
    const { lat, long, cliente_id, empresa_id, limit_date } = data;

    const body = {
      customer_id: data.customer_id,
      task_id: data.task_id,
      lat,
      long,
      cliente_id,
      empresa_id,
      limit_date,
    };

    const url: string = `${this.API_URL}/created-task`;

    return this.httpClient
      .post<TaskResponseDTO>(url, body)
      .pipe(catchError(this.handleError));
  }

  saveResponse(responseId: number, data: ISaveResponseTask): Observable<any> {
    const url: string = `${this.API_URL}/created-task/${responseId}`;

    const body = { ...data };

    return this.httpClient
      .post<any>(url, body)
      .pipe(catchError(this.handleError));
  }

  getCreatedTasks(id: string): Observable<CreatedTasksDTO> {
    console.log('init getCreatedTasks');
    const timestamp = new Date().getTime();
    const url: string = `${this.API_URL}/created-task/users/${id}?_=${timestamp}`;

    return this.httpClient
      .get<CreatedTasksDTO>(url)
      .pipe(catchError(this.handleError));
  }

  getAssignedTasks(userId: string): Observable<CreatedTasksDTO> {
    console.log('init getAssignedTasks');
    const timestamp = new Date().getTime();
    const url: string = `${this.API_URL}/created-task/assign-for-user/?userId=${userId}&_=${timestamp}`;

    return this.httpClient
      .get<CreatedTasksDTO>(url)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    console.error('An error occurred:', error);

    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    return throwError('Ocurrió un error, intenta nuevamente.');
  }

  updateStatus(taskId, taskCompleted: ITaskCompleted): Observable<any> {
    const url: string = `${this.API_URL}/created-task/${taskId}`;

    const body = taskCompleted;

    return this.httpClient
      .put<any>(url, body)
      .pipe(catchError(this.handleError));
  }

  duplicateCreatedTask(taskId): Observable<any> {
    const url: string = `${this.API_URL}/created-task/duplicate/${taskId}`;

    return this.httpClient
      .post<any>(url, {})
      .pipe(catchError(this.handleError));
  }

  fetchAssignedTasks(): Observable<number[]> {
    this.assignedPendingCount = 0;
    this.assignedCompletedCount = 0;
    return this.getAssignedTasks(this.user.id).pipe(
      finalize(() => {
        console.log('final getAssignedTasks');
      }),
      tap(() => {
        this.assignedPendingCount = 0;
        this.assignedCompletedCount = 0;
      }),
      map((data: CreatedTasksDTO) => {
        data.data.forEach((t) => {
          if (t.template_status === ETaskStatus.CREATED)
            this.assignedPendingCount += 1;
          else if (
            t.template_status === ETaskStatus.COMPLETED ||
            t.template_status === ETaskStatus.CONFIRMED ||
            t.template_status === ETaskStatus.REJECTAD
          )
            this.assignedCompletedCount += 1;
        });

        this.assignedTasks = data.data.reduce((acc, obj): TaskByClient[] => {
          const existClient = acc.find(
            (c) => c.customer.cliente_id === obj.customer.cliente_id
          );

          if (!existClient)
            acc = [...acc, { customer: obj.customer, tasks: [obj] }];
          else {
            acc = acc.map((c) => {
              if (c.customer.cliente_id === obj.customer.cliente_id) {
                c.tasks.push(obj);
              }

              return c;
            });
          }

          return acc;
        }, [] as TaskByClient[]);

        localStorage.setItem(
          'assigned_task',
          JSON.stringify(this.assignedTasks)
        );

        return [this.assignedPendingCount, this.assignedCompletedCount];
      }),
      catchError(this.handleError)
    );
  }

  fetchCreatedTasks(): Observable<number[]> {
    this.createdPendingCount = 0;
    this.createdCompletedCount = 0;
    return this.getCreatedTasks(this.user.id).pipe(
      finalize(() => {
        console.log('final getCreatedTasks');
      }),
      tap(() => {
        this.createdPendingCount = 0;
        this.createdCompletedCount = 0;
      }),
      map((data: CreatedTasksDTO) => {
        data.data.forEach((t) => {
          if (t.template_status === ETaskStatus.CREATED) {
            this.createdPendingCount += 1;
          } else if (
            t.template_status === ETaskStatus.COMPLETED ||
            t.template_status === ETaskStatus.CONFIRMED ||
            t.template_status === ETaskStatus.REJECTAD
          ) {
            this.createdCompletedCount += 1;
          }
        });

        this.assignedCompletedCount = this.createdCompletedCount;

        this.createdTask = data.data.reduce((acc, obj): TaskByClient[] => {
          const existClient = acc.find(
            (c) => c.customer.cliente_id === obj.customer.cliente_id
          );

          if (!existClient)
            acc = [...acc, { customer: obj.customer, tasks: [obj] }];
          else {
            acc = acc.map((c) => {
              if (c.customer.cliente_id === obj.customer.cliente_id) {
                c.tasks.push(obj);
              }

              return c;
            });
          }

          return acc;
        }, [] as TaskByClient[]);

        localStorage.setItem('created_task', JSON.stringify(this.createdTask));

        return [this.createdPendingCount, this.createdCompletedCount];
      }),
      catchError(this.handleError)
    );
  }

  public setTabIndex(value): void {
    this.isTabIndex_0.next(value);
  }

  findReasonRejection(taskList, taskId) {
    for (const customer of taskList) {
      for (const task of customer.tasks) {
        if (task.id === taskId) {
          return task.reason_rejection;
        }
      }
    }
    return undefined;
  }
}
