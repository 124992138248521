import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { auth } from 'src/environments/environment';
import { UserModel } from '../models/user.model';
import { IndicatorModel } from '../models/indicator.model';

@Injectable({ providedIn: 'root' })
export class IndicatorsService {
  private API_URL: string = auth.urlConfig;
  private user: UserModel;

  constructor(private httpClient: HttpClient) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
  }

  getIndicator(clientId?: string, enterpriseId?: string) {
    const url = `${this.API_URL}/indicators/customer/${clientId}/${enterpriseId}`;

    return this.httpClient.get<IndicatorModel[]>(url).pipe(
      catchError(this.handleError),
      map((data) => {
        const indicators = data['indicators'] || [];

        return indicators.map((indicator: IndicatorModel) => {
          indicator.url = indicator.url.replace('{CLIENT_ID}', `${clientId}`);

          return indicator;
        });
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    return throwError('Ocurrió un error, intenta nuevamente.');
  }
}
