<mat-tab-group
  class="bottom-navbar tab-container"
  animationDuration="0ms"
  [(selectedIndex)]="selectedTabIndex"
  (selectedIndexChange)="onChangeTab($event)"
>
  <mat-tab label="Inicio">
    <ng-template mat-tab-label>
      <div class="icon-container">
        <mat-icon [class.active]="selectedTabIndex === 0">home</mat-icon>
        <span [class.active]="selectedTabIndex === 0">Inicio</span>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab label="Clientes">
    <ng-template mat-tab-label>
      <div class="icon-container">
        <mat-icon [class.active]="selectedTabIndex === 1"
          >supervisor_account</mat-icon
        >
        <span [class.active]="selectedTabIndex === 1">Clientes</span>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab label="Sync">
    <ng-template mat-tab-label>
      <div class="icon-container">
        <mat-icon [class.active]="selectedTabIndex === 2">sync</mat-icon>
        <div
          [ngClass]="{ 'right-20': !showTab, 'right-10': showTab }"
          class="badge-sync"
        >
          {{ syncPollsCount$ | async }}
        </div>
        <span [class.active]="selectedTabIndex === 2">Sync</span>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab label="Encuestas">
    <ng-template mat-tab-label>
      <div class="icon-container">
        <mat-icon [class.active]="selectedTabIndex === 3">ballot</mat-icon>
        <span [class.active]="selectedTabIndex === 3">Encuestas</span>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab label="Tareas" *ngIf="showTab">
    <ng-template mat-tab-label>
      <div class="icon-container">
        <mat-icon [class.active]="selectedTabIndex === 4">task_alt</mat-icon>
        <span [class.active]="selectedTabIndex === 4">Tareas</span>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<ngx-spinner
  zIndex="70"
  name="synchronizing-navbar"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-clip-rotate-pulse"
>
  <div class="progress-upload">
    <div *ngIf="!simpleSpinner">
      <div style="margin-bottom: 12px">Sincronizando</div>
      <mat-progress-bar
        mode="determinate"
        [value]="progressValue"
        class="sync-progress-bar"
      ></mat-progress-bar>
      <div style="margin-top: 16px">
        {{ progressText }}
        <span>{{ progressValue }}%</span>
      </div>
    </div>
    <div *ngIf="simpleSpinner">
      <div style="margin-bottom: 12px">{{ progressText }}</div>
    </div>
  </div>
</ngx-spinner>
