import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private spinnerSubject = new BehaviorSubject<{
    simple: boolean;
    show: boolean;
    message?: string;
    progress?: { progressText: string; progressValue: number };
  }>({ show: false, simple: false });

  spinnerState$ = this.spinnerSubject.asObservable();

  showSpinner(
    simple: boolean = false,
    message?: string,
    progress?: { progressText: string; progressValue: number }
  ) {
    this.spinnerSubject.next({
      simple: simple,
      show: true,
      message,
      progress,
    });
  }

  hideSpinner() {
    this.spinnerSubject.next({ show: false, simple: false });
  }

  updateProgress(progress: { progressText: string; progressValue: number }) {
    const currentState = this.spinnerSubject.value;
    this.spinnerSubject.next({
      ...currentState,
      progress,
    });
  }
}
