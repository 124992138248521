import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { auth } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ImagesService {
  private API_URL = `${auth.urlConfig}`;

  constructor(private httpClient: HttpClient) {}

  getImageHelpQuestion(path: string) {
    const url = `${auth.urlConfig}/file/signedurl?path=${path}`;
    return this.httpClient
      .get<{ filename: string; url: string }>(url)
      .pipe(retry(3), catchError(this.handleError));
  }

  getImageHelpQuestionBase64(pathFile: string, inconming: string) {
    const url = `${auth.urlConfig}/file/download/base64?pathFile=${pathFile}&inconming=${inconming}`;
    return this.httpClient.get<{ filename: string; url: string }>(url).pipe(
      // retry(3),
      catchError(this.handleError)
      /* catchError((error) => {
        return this.handleError(error);
      }) */
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );

      // return throwError('Ocurrió un error, intenta nuevamente.');
      return throwError(
        () => new Error('Ocurrió un error, intenta nuevamente.')
      );
    }
    // return an observable with a user-facing error message
    return throwError('Ocurrió un error, intenta nuevamente.');
  }
}
