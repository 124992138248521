import { NgModule } from '@angular/core';
import { LoginComponent } from './login/components/login/login.component';

import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { PollsComponent } from './polls/components/polls/polls.component';
import { ClientsComponent } from './polls/components/clients/clients.component';
import { QuestionComponent } from './polls/components/questions/question/question.component';
import { InfoextraComponent } from './polls/components/infoextra/infoextra.component';
import { MessageInitComponent } from './polls/components/message-init/message-init.component';
import { MessageFinishComponent } from './polls/components/message-finish/message-finish.component';
import { B2bloginComponent } from './polls/components/b2blogin/b2blogin.component';
import { DashboardPollComponent } from './polls/components/dashboard-poll/dashboard-poll.component';
import { DashboardPollGoalsComponent } from './polls/components/dashboard-poll-goals/dashboard-poll-goals.component';

import { DashboardPeriodPollComponent } from './polls/components/dashboard-period-poll/dashboard-period-poll.component';
import { MapsComponent } from './shared/components/maps/maps.component';
import { InitComponent } from './init/init.component';
import { AutloginComponent } from './autlogin/autlogin.component';
import { SynchronizationComponent } from './synchronization/components/sync/synchronization.component';
import { HomeComponent } from './home/components/home/home.component';
import { PublishedPollsComponent } from './published/components/published-polls/published-polls.component';
import { TasksComponent } from './tasks/components/tasks/tasks.component';
import { TasksAssignedComponent } from './tasks/components/tasks-assigned/tasks-assigned.component';
import { TasksCreateComponent } from './tasks/components/tasks-create/tasks-create.component';
import { TasksCreatedComponent } from './tasks/components/tasks-created/tasks-created.component';
import { ResponseViewComponent } from './tasks/components/response-view/response-view.component';
import { ResponseQuestionsComponent } from './tasks/components/tasks-assigned/components/response-questions/response-questions.component';
import { QuestionsToResponseComponent } from './tasks/components/tasks-assigned/components/questions/questions.component';
import { EnterpriseGuard } from './polls/guards/enterprise.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'synchronization',
    component: SynchronizationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'published',
    component: PublishedPollsComponent,
    canActivate: [AuthGuard],
  },
  { path: '', component: InitComponent },
  { path: 'poll', component: PollsComponent, canActivate: [AuthGuard] },
  {
    path: 'poll/dashboard',
    component: DashboardPollComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'poll/dashboard-period',
    component: DashboardPeriodPollComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'poll/dashboard-goals',
    component: DashboardPollGoalsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'poll/client',
    component: ClientsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'poll/client/info',
    component: InfoextraComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'poll/client/init',
    component: MessageInitComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'poll/client/finish',
    component: MessageFinishComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'poll/client/questions',
    component: QuestionComponent,
    canActivate: [AuthGuard],
  },
  { path: 'b2b/login', component: B2bloginComponent },
  { path: 'autlogin', component: AutloginComponent, canActivate: [AuthGuard] },
  {
    path: 'maps',
    component: MapsComponent,
    canActivate: [AuthGuard, EnterpriseGuard],
  },
  {
    path: 'tasks',
    component: TasksComponent,
    canActivate: [AuthGuard, EnterpriseGuard],
  },
  {
    path: 'tasks/assigned',
    component: TasksAssignedComponent,
    canActivate: [AuthGuard, EnterpriseGuard],
  },
  {
    path: 'tasks/create/:id',
    component: TasksCreateComponent,
    canActivate: [AuthGuard, EnterpriseGuard],
  },
  {
    path: 'tasks/created',
    component: TasksCreatedComponent,
    canActivate: [AuthGuard, EnterpriseGuard],
  },
  {
    path: 'tasks/info/:id',
    component: ResponseViewComponent,
    canActivate: [AuthGuard, EnterpriseGuard],
  },
  {
    path: 'tasks/:id/response',
    component: ResponseQuestionsComponent,
    canActivate: [AuthGuard, EnterpriseGuard],
  },
  {
    path: 'tasks/:id/response/:question',
    component: QuestionsToResponseComponent,
    canActivate: [AuthGuard, EnterpriseGuard],
  },
  { path: '**', redirectTo: '404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AuthGuard, EnterpriseGuard],
})
export class AppRoutingModule {}
