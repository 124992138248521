import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { forkJoin, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map, retry } from 'rxjs/operators';

import { auth } from 'src/environments/environment';
import { ClientModel } from '../models/client.model';
import { PollModel } from '../models/poll.model';
import { LocationService } from './location.service';
import { UserModel } from '../models/user.model';
import { UserSalesEstucture } from '../models/usersaleestucture';
import { EnterpriseModel } from '../models/enterprise';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  private API_URL = `${auth.urlConfig}`;

  constructor(
    private httpClient: HttpClient,
    locationService: LocationService
  ) {}
  getClientsByUser(
    pageNum: number,
    pageSize: number,
    searchString: string,
    currentPosition: any,
    total: number,
    enterprise: any,
    user: UserModel,
    methodSearch: string,
    distance: number
  ) {
    // const url = `${auth.urlConfig}/poll/user/?email=${this.user.username}`;
    let params = new HttpParams();

    params = params.set('pageNum', pageNum);
    params = params.set('pageSize', pageSize);
    params = params.set('searchString', searchString);
    params = params.set('total', total);
    params = params.set('methodSearch', methodSearch);
    if (enterprise !== undefined) {
      params = params.set('enterpriseID', enterprise);
    }
    if (methodSearch !== undefined) {
      params = params.set('methodSearch', methodSearch);
    }
    if (distance > 0) {
      params = params.set('distance', distance);
    }
    if (currentPosition && currentPosition.lat && currentPosition.lng) {
      params = params.set(
        'currentPosition',
        `${currentPosition.lat},${currentPosition.lng}`
      );
    }

    // Para Filtar por los  categorias seleccionas

    const resEnterprise = JSON.parse(
      localStorage.getItem('enterpriseFilterAllClients')
    );
    const res = JSON.parse(localStorage.getItem('filterClientsAllData'));
    if (
      res !== undefined &&
      res !== null &&
      resEnterprise !== undefined &&
      resEnterprise !== null
    ) {
      const selectedEnterprise = resEnterprise.find(
        (re) => re.user_id === user.id
      );
      const filterClientsAllData = res.filter(
        (aif) =>
          aif.user_id === user.id && aif.enterprise_id === selectedEnterprise.id
      );
      filterClientsAllData.forEach((itemParam) => {
        params = params.append(itemParam.source, itemParam.code.toString());
      });
    }
    // Sobre aseguramos que lea la geolocalizacion
    if (
      methodSearch.toLocaleLowerCase() == 'maps' &&
      currentPosition == undefined
    ) {
      params = params.delete('currentPosition');
      currentPosition = JSON.parse(localStorage.getItem('currenLocation'));
      params = params.set(
        'currentPosition',
        `${currentPosition.lat},${currentPosition.lng}`
      );
    }
    const url = `${auth.urlConfig}/pollsters/clients`;

    return this.httpClient.get<ClientModel[]>(url, { params }).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }

  getPollofClientsWithExclude(clientId: string, pollId: number) {
    // const url = `${auth.urlConfig}/poll/user/?email=${this.user.username}`;
    let params = new HttpParams();
    params = params.set('clientId', clientId);
    params = params.set('pollId', pollId);

    const url = `${auth.urlConfig}/pollsters/clients/exceptpoll`;

    return this.httpClient.get<PollModel[]>(url, { params }).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }

  getClientsById(pollId: number, clientId: string) {
    // const url = `${auth.urlConfig}/poll/user/?email=${this.user.username}`;
    let params = new HttpParams();
    params = params.set('clientId', clientId);
    params = params.set('pollId', pollId);
    const url = `${auth.urlConfig}/pollsters/clients/infopoll`;

    return this.httpClient.get<any>(url, { params }).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }

  getCustomerCandidateById(customerCandidateId: number, pollId: number) {
    const url = `${auth.urlConfig}/pollsters/clients/candidate`;
    let params = new HttpParams();

    params = params.set('customerId', customerCandidateId);
    params = params.set('pollId', pollId);

    return this.httpClient.get<any>(url, { params }).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }

  getCustomerCategories(source: string) {
    const url = `${auth.urlConfig}/pollsters/clients/categories`;
    let params = new HttpParams();

    params = params.set('source', source);

    return this.httpClient.get<any>(url, { params }).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }

  getClientsBySellers(data: any) {
    const url = `${auth.urlConfig}/pollsters/clients/clients-offline`;
    let params = new HttpParams();
    let sellers: Array<any> = [];
    data.forEach((seller) => {
      sellers.push({
        vendedor_id: seller.code,
        empresa_id: seller.empresa_id,
      });
    });
    params = params.append('sellers', JSON.stringify(sellers));
    return this.httpClient.get<any>(url, { params }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getPollClientBySellers(data: any) {
    const url = `${auth.urlConfig}/pollsters/clients/polls-offline`;
    let params = new HttpParams();
    let sellers: Array<any> = [];
    data.forEach((seller) => {
      sellers.push({
        vendedor_id: seller.code,
        empresa_id: seller.empresa_id,
      });
    });
    params = params.append('sellers', JSON.stringify(sellers));
    return this.httpClient.get<any>(url, { params }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getClientsAndPollsBySellers(data: any) {
    let clients$ = this.getClientsBySellers(data).pipe(
      map((clients) => ({ clients }))
    );
    let polls$ = this.getPollClientBySellers(data).pipe(
      map((polls) => ({ polls }))
    );

    return forkJoin([clients$, polls$]).pipe(
      map(([clients, polls]) => {
        let clientsBySellers = clients['clients'];
        clientsBySellers['polls'] = polls['polls']['polls'];

        return clientsBySellers;
      })
    );
  }

  getResponsesPollsBySeller({ clientId, pollId }) {
    const url = `${auth.urlConfig}/pollsters/clients/pollresponsesbyseller`;
    let params = new HttpParams();
    params = params.append('clientId', clientId);
    params = params.append('pollId', pollId);
    return this.httpClient.get<any>(url, { params }).pipe(
      //   // retry(3),
      catchError(this.handleError)
    );
  }

  getAllPollCustomerAvailable(
    customerCandidateId: string,
    enterpriseID: number
  ) {
    const url = `${auth.urlConfig}/pollsters/clients/polls`;
    let params = new HttpParams();

    params = params.set('customerId', customerCandidateId);
    params = params.set('enterpriseID', enterpriseID);

    return this.httpClient.get<any>(url, { params }).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }

  getCustomerCategoriesFilter(categories: any[], enterpriseID: number) {
    const url = `${auth.urlConfig}/pollsters/clients/categories`;
    let params = new HttpParams();
    let httpParams = new HttpParams();
    params = params.set('enterpriseID', JSON.stringify(enterpriseID));
    Object.values(categories).forEach((category) => {
      httpParams = httpParams.append(category.source, category.code.toString());
    });
    httpParams = httpParams.append(
      'enterpriseID',
      JSON.stringify(enterpriseID)
    );
    const requestOptions = {
      params: httpParams,
    };

    return this.httpClient
      .get<any>(url, requestOptions)
      .pipe(catchError(this.handleError));
  }

  getSkuByCliendId(clientId: number) {
    const url = `${auth.urlConfig}/skustypes/customers`;
    return this.httpClient
      .get<any[]>(url, { params: { clientId } })
      .pipe(catchError(this.handleError));
  }

  getAllSkus(enterpriseIds: []) {
    const params = new HttpParams().set(
      'enterpriseIds',
      enterpriseIds.join(',')
    );
    const requestOptions = {
      params,
    };
    const url = `${auth.urlConfig}/skustypes/customers`;
    return this.httpClient
      .get<any[]>(url, requestOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    console.error('An error occurred:', error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      return throwError('Ocurrió un error, intenta nuevamente.');
    }
    // return an observable with a user-facing error message
    return throwError('Ocurrió un error, intenta nuevamente.');
  }
}
